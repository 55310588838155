module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Bernasconi SA Formaggi & Salumi","short_name":"Bernasconi SA","start_url":"/","background_color":"#922c31","theme_color":"#922c31","display":"standalone","icon":"src/images/bsa.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"e05eb68c9e89195650ced1d8a5ca914f"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
